body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.collapse-content.collapsed {
  display: none;
}

.collapsed-content.expanded {
  display: block;
}

.image-circle {
  border-radius: "50%" !important;
}
.background-mount {
  background-image: url(../../static/media/sign_in_background.07dc149d.png);
  height: 100vh;
  width: 100vw;
  background-position: center;
  background-repeat: no-repeat;
}

.modal-background-blur {
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(19px);
  backdrop-filter: blur(19px);
  border: solid 1px #707070;
  background-color: #ffffff;
}

.model-content {
  font-family: MicrosoftYaHeiUI;
  font-size: 44px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  text-align: left;
  padding-top: 15%;
  color: #f54b4b;
}

.modal-background {
  width: 417px;
  height: 249px;
  border-radius: 36px;
  box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #dfdded;
}

.background-nav {
  background-image: url(../../static/media/navbar_background.067a3e90.png);
  background-position: top;
  background-repeat: no-repeat;
  height: 100vh;
  padding-right: 15px;
  background-size: cover;
}

.background-checkbox {
  background-image: url(../../static/media/rectangle.d4729fae.svg);
  background-position: center;
}

.background-nav-button {
  background-image: url(../../static/media/nav_button_background.6f35a25e.svg);
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  margin: 2px;
  background-size: auto;
  cursor: pointer;
}

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}

.div-input {
  background-color: #f6f6f6;
  border: none;
  color: #0d0d0d;
  padding: 10px 10px;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  width: 100%;
  height: auto !important;
  border: 0px solid #f6f6f6 !important;
  transition: all 0.5s ease-in-out;
  border-radius: 15px !important;
}

.div-input :placeholder {
  color: #cccccc;
}

.input-in-div {
  border: none;
  outline-color: white;
  border-bottom: 0px solid white;
  background-color: white !important;
  padding-left: 5px;
  width: 93%;
  line-height: normal !important;
  line-height: initial !important;
}

.input-in-div :focus {
  border: none;
  outline-color: white;
  border-bottom: 0px solid white;
  background-color: white !important;
  padding-left: 5px;
  width: 93%;
  line-height: normal !important;
  line-height: initial !important;
}

.input-in-div :placeholder {
  color: transparent;
  border: none;
  outline-color: white;
  border-bottom: 0px solid white;
  background-color: white !important;
  padding-left: 5px;
  width: 93%;
  line-height: normal !important;
  line-height: initial !important;
  outline-color: transparent !important;
}

.word-input-in-div {
  border: none;
  outline-color: white;
  border-bottom: 0px solid white;
  background-color: white !important;
  width: 83%;
  line-height: normal !important;
  line-height: initial !important;
}

.word-input-title {
  height: 29px;
  padding-left: 5px;
  font-family: MicrosoftYaHeiUI;
  font-size: 23px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.26;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.word-input-in-div :focus {
  border: none;
  outline-color: white;
  border-bottom: 0px solid white;
  background-color: #d7d9e3 !important;
  width: 83%;
  line-height: normal !important;
  line-height: initial !important;
}

.word-input-in-div :placeholder {
  color: transparent;
  border: none;
  outline-color: white;
  border-bottom: 0px solid white;
  background-color: #d7d9e3 !important;
  width: 83%;
  line-height: normal !important;
  line-height: initial !important;
  outline-color: transparent !important;
}

.word-div-input {
  background-color: #d7d9e3 !important;
  border: none;
  color: #0d0d0d;
  padding: 10px 10px;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  width: 100%;
  height: auto !important;
  border: 0px solid #f6f6f6 !important;
  transition: all 0.5s ease-in-out;
  border-radius: 15px !important;
}

.word-div-input :placeholder {
  color: #d7d9e3;
}

.word-input-in-div {
  border: none;
  outline-color: white;
  border-bottom: 0px solid white;
  background-color: #d7d9e3 !important;
  padding-left: 5px;
  width: 83%;
  line-height: normal !important;
  line-height: initial !important;
}

.center {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.purple-color {
  color: #603d8f;
}

.input-title {
  margin-left: 10px !important;
  font-size: 12pt !important;
  color: #603d8f;
}

.modal-input-title {
  margin-left: 5px !important;
  font-size: 12pt !important;
  color: #0d0d0d;
}

.button-gradient-purple {
  background-image: linear-gradient(#603d8f, #9170bd, #c5a7ef);
  margin: 5px;
  width: 100%;
  font-family: "Arial Black", Gadget, sans-serif;
  font-size: 16px;
  padding: 13px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: #fff;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  cursor: pointer;
  display: inline-block;
  border-radius: 15px;
  border: 0ch;
}

.button-gradient-purple:hover {
  background-position: right center;
}

.button-gradient-purple:disabled,
.button-gradient-purple[disabled] {
  background-color: #b7abca !important;
  background-image: none;
  cursor: no-drop;
}

.container-input {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */

.container-input input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
}

/* On mouse-over, add a grey background color */

.container-input:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */

.container-input input:checked ~ .checkmark {
  background-color: #dc3c47;
}

/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */

.container-input input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */

.container-input .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.clickable {
  cursor: pointer;
}

.nav-button-label {
  padding-left: 10px !important;
  color: #6509df;
  cursor: pointer;
}

.nav-button-icon {
  padding-left: 35px !important;
}

.header-button-label {
  padding: 2px !important;
  color: white;
}

.header-button-icon {
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
  padding-left: 15px !important;
}

.padding-zero {
  padding: 0 !important;
}

.margin-zero {
  margin: 0 !important;
}

.nav-header-label {
  padding-left: 10px !important;
  color: #ffffff !important;
  font-weight: bold;
}

.nav-header-icon {
  padding-left: 10px !important;
}

.input-error {
  background-color: #f6f6f6;
  border: none;
  color: #ce2424;
  padding: 5px;
  text-decoration: none;
  display: inline-block;
  margin: 5px;
  width: 100%;
  height: auto !important;
  border: 0px solid #f6f6f6 !important;
  transition: all 0.5s ease-in-out;
  border-radius: 15px !important;
}

.Toastify__toast--error {
  background: unset !important;
  background-color: #f6f6f6 !important;
}

.Toastify__progress-bar {
  background-color: #603d8f !important;
}

.Toastify__toast-body {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.Toastify__toast {
  min-height: unset;
}

.header-background {
  background-image: linear-gradient(to right, #8d8efb, #603d8f);
}

.navbar {
  padding: 0.5rem !important;
}

.navbar-title {
  color: #e4e4e4;
  font-size: large;
  padding-left: 0.5rem;
}

.user-card-text {
  color: white;
  font-size: large;
  padding-left: 0.5rem;
}

.card-item {
  border: none !important;
  margin: 10px;
  margin-top: 30px;
  width: 351px;
  height: 279px;
  border-radius: 12px;
  background-position: 10% 50%;
  background-repeat: no-repeat;
  box-shadow: 0 8px 5px 0 rgba(0, 0, 0, 0.43);
  background-image: linear-gradient(to right, #9293fc 0%, #e4e1ec 54%, #deddee);
}

.card-user {
  border: none !important;
  margin: 10px;
  margin-top: 30px;
  width: 351px;
  height: 279px;
  border-radius: 12px;
  background-position: 10% 50%;
  background-repeat: no-repeat;
  box-shadow: 0 8px 5px 0 rgba(0, 0, 0, 0.43);
  background-image: linear-gradient(
    to bottom,
    #bbb4dc 0%,
    #c0bdcc 54%,
    #4b3e88
  );
}

.card-item-title {
  width: 166.7px;
  height: 25.8px;
  border-radius: 11px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #e5cfcd;
}

.card-item-body {
  height: 70%;
  margin: 8px !important;
  margin-top: 40px;
}

.card-item-bottom {
  padding: 5%;
  width: 60%;
  margin: auto;
}

.card-list-title {
  width: 99%;
  height: 62.6px;
  border-radius: 35.8px;
  background-color: #d6c8e7;
}

.table-outer {
  width: 100%;
  margin: 4.5px;
  margin-top: 10px;
  padding: 8px;
}

.table-header-outer {
  width: 100%;
  height: 52.5px;
  padding-top: 7px;
  border-radius: 26px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-image: linear-gradient(to bottom, #a8a8f8 -182%, #c6b3df 100%);
}

.table-header-inner {
  height: 36px;
  padding-top: 5px;
  border-radius: 18px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #dfd3de;
}

.table-inner {
  width: 98%;
  margin: auto;
}

.table-item {
  min-height: 126.7px;
  height: auto;
  border-radius: 26px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #dcd8ef;
  margin-top: 10px;
}
.form-control {
  display: flex !important;
}
.table-col {
  padding-top: 50px;
  overflow: auto;
}

.table-header {
  margin: auto;
}

.button-primary {
  max-width: 108px;
  min-width: 70px;
  height: 37.5px;
  border-radius: 22px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: transparent;
  background-image: linear-gradient(to bottom, #603d8f, #9170bd 50%, #c4a6ee);
}

.button-primary:hover {
  background-position: right center;
}

.button-primary:disabled,
.button-primary[disabled] {
  background-color: #b7abca !important;
  background-image: none;
  cursor: no-drop;
}

.bar-blue-middle {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-position: left;
  height: 75px;
  margin-left: -1px;
  margin-right: -1px;
  background-repeat: round no-repeat;
  background-image: url(../../static/media/bar_blue_middle.b9247c75.svg);
}

.bar-blue-start {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-position: left;
  height: 75px;
  margin-left: -1px;
  margin-right: -1px;
  background-repeat: round no-repeat;
  background-image: url(../../static/media/bar_blue_start.8e592556.svg);
}

.bar-blue-end {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-position: left;
  height: 75px;
  margin-left: -1px;
  margin-right: -1px;
  background-repeat: round no-repeat;
  background-image: url(../../static/media/bar_blue_end.5a8f52a3.svg);
}

.cured-button {
  width: 205.5px;
  height: 75px;
  color: white;
  vertical-align: middle;
  border: transparent;
  background-position: left;
  background-repeat: round no-repeat;
  background-image: url(../../static/media/cured_background_blue.08bc499b.svg);
}

.cured-button:disabled,
.cured-button[disabled] {
  background-image: url(../../static/media/cured_background_grey.2c7f69c5.svg);
}

.cured-button-text {
  padding: 12%;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin: auto;
}

.button-primary-text {
  font-family: MicrosoftYaHeiUI;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  margin: auto;
  cursor: pointer;
}

.div-search {
  border: none;
  outline-color: #e6e6e6;
  border-bottom: 0px solid #e6e6e6;
  background-color: #e6e6e6 !important;
  padding-left: 5px;
  border-radius: 50.8px !important;
  line-height: normal !important;
  line-height: initial !important;
}

.table thead th {
  border: none !important;
  margin: auto;
  padding-left: 1rem;
}

.table {
  width: 98%;
  margin-left: 1rem;
  margin-right: 1rem;
}

.search {
  border: none;
  outline-color: #e6e6e6;
  border-bottom: 0px solid #e6e6e6;
  background-color: #e6e6e6 !important;
  padding-left: 5px;
  width: 140px;
  line-height: normal !important;
  line-height: initial !important;
}

.search :focus {
  border: none;
  outline-color: #e6e6e6;
  border-bottom: 0px solid #e6e6e6;
  background-color: #e6e6e6 !important;
  padding-left: 5px;
  width: 140px;
  line-height: normal !important;
  line-height: initial !important;
}

.search :placeholder {
  color: transparent;
  border: none;
  outline-color: #e6e6e6;
  border-bottom: 0px solid #e6e6e6;
  background-color: #e6e6e6 !important;
  padding-left: 5px;
  width: 140px;
  line-height: normal !important;
  line-height: initial !important;
  outline-color: transparent !important;
}

.modal-background-create {
  padding: 10px;
  min-width: 50vw;
  max-height: 70%;
  background-image: linear-gradient(271deg, #e4e4e4, #938cde);
}

.modal-text-title {
  width: 228.6px;
  height: 39.7px;
  padding-top: 5px;
  border-radius: 27px;
  margin: auto;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #d7d9e3;
}

.modal-input-in-div {
  border: none;
  outline-color: white;
  border-bottom: 0px solid white;
  padding: 5px;
  line-height: normal !important;
  line-height: initial !important;
  border-radius: 8px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #d7d9e3;
}

.modal-input-in-div :focus {
  border: none;
  outline-color: white;
  border-bottom: 0px solid white;
  padding: 5px;
  line-height: normal !important;
  line-height: initial !important;
  border-radius: 8px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #d7d9e3;
}

.modal-input-in-div :placeholder {
  color: transparent;
  border: none;
  outline-color: white;
  border-bottom: 0px solid white;
  padding: 5px;
  line-height: normal !important;
  line-height: initial !important;
  outline-color: transparent !important;
  border-radius: 8px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #d7d9e3;
}

.modal-close {
  display: block;
  width: 10.9px;
  height: 10.9px;
  cursor: pointer;
}

.dot-circle {
  margin-left: 10px;
  margin-right: 10px;
  width: 10px;
  height: 10px;
  text-align: center;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 10px;
  margin: auto;
}

.border-word {
  padding: 10px !important;
  margin: 10px 10px 0 10px !important;
}

.inalienable-row {
  margin: -1px 10px 0 10px !important;
}

.p1-color {
  background-color: #19d5ae;
}

.p2-color {
  background-color: #ff9a44;
}

.p3-color {
  background-color: #f25c62;
}

.p4-color {
  background-color: #ebd234;
}

.p5-color {
  background-color: #eb39e5;
}

.p6-color {
  background-color: #30a9df;
}

.p7-color {
  background-color: #6aeb34;
}

@media (max-width: 575.98px) {
  .card-columns {
    -webkit-column-count: 1;
            column-count: 1;
  }
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 2;
            column-count: 2;
  }
}

@media (min-width: 768px) {
  .card-columns {
    -webkit-column-count: 3;
            column-count: 3;
  }
}

@media (min-width: 992px) {
  .card-columns {
    -webkit-column-count: 4;
            column-count: 4;
  }
}

@media (min-width: 1200px) {
  .card-columns {
    -webkit-column-count: 4;
            column-count: 4;
  }
}

.img-center {
  margin: auto !important;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

